import React from "react";
import logo from "./takhq.png";
import "./App.css";
import EmailIcon from "@mui/icons-material/Email"; // Import Email icon
import ArticleIcon from "@mui/icons-material/Article"; // Import Article icon
import Grid from "@mui/material/Grid"; // Import Grid component
import Card from "@mui/material/Card"; // Import Card components
import CardContent from "@mui/material/CardContent";
import Container from "@mui/material/Container"; // Import Container component

function App() {
  return (
    <div className="App">
      <Container maxWidth="md" className="App-header">
        {" "}
        {/* Adjust maxWidth as needed */}
        <header className="App-header">
          <img src={logo} alt="logo" style={{ width: "150px" }} />
          <p>
            Custom Software & Hardware Solutions,
            <br /> Rapidly Accelerating Defense Tech
          </p>
          {/* Grid layout */}
          <Grid container spacing={2}>
            {/* Left Cell: Contact Us in Card */}
            <Grid item xs={6}>
              <Card sx={{ background: "transparent" }}>
                <CardContent className="card-content">
                  <a
                    className="App-link"
                    href="mailto:tak@atakhq.com"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <EmailIcon fontSize="large" />
                    <br />
                    Contact Us
                  </a>
                  <p style={{ fontSize: "12px", color: "white" }}>
                    <b>Please use a valid mil/gov email address.</b>
                  </p>
                </CardContent>
              </Card>
            </Grid>

            {/* Right Cell: Documentation Hub in Card */}
            <Grid item xs={6}>
              <Card sx={{ background: "transparent" }}>
                <CardContent className="card-content">
                  <a
                    className="App-link"
                    href="https://docs.atakhq.com"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <ArticleIcon fontSize="large" />
                    <br />
                    Documentation Hub
                  </a>
                  <p style={{ fontSize: "12px", color: "white" }}>
                    <b>Restricted Access</b>
                  </p>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </header>
      </Container>
    </div>
  );
}

export default App;
